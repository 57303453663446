<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="ГРН">
                <b-form-input
                  v-model="filter.grn"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('grn')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="СТС">
                <b-form-input
                  v-model="filter.sts"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('sts')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Допуск"
              >
                <el-select
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Тип"
              >
                <el-select
                  v-model="filter.type"
                  class="form-control"
                  name="type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  @input="updateFilter('type')"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Транспортные средства
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            class="ml-2"
            to="/transport-vehicles/add"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="model.type.name"
            slot-scope="props"
          >
            {{ props.row.model.type.name }}
            {{ (props.row.model.containerTransportVehicle ? ' (контейнеровоз)':'') }}
          </span>
          <span
            slot="contractor"
            slot-scope="props"
          >
            <span
              v-for="contractorVehicle in props.row.contractorVehicles"
              :key="`contractor-vehicle-${contractorVehicle.id}-contractor-${contractorVehicle.contractor.id}`"
            >{{ contractorVehicle.contractor.name }}<br></span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 0"
              class="text-secondary"
            >Новый</span>
            <span
              v-if="props.row.status === 1"
              class="text-success"
            >Допущен</span>
            <span
              v-else-if="props.row.status === 2"
              class="text-warning"
            >На верификации СБ</span>
            <span
              v-else-if="props.row.status === 3"
              class="text-danger"
            >Не допущен</span>
            <span
              v-else-if="props.row.status === 4"
              class="text-primary"
            >Проверка логистом</span>
            <span
              v-else-if="props.row.status === 5"
              class="text-danger"
            >Отклонен</span>&ensp;
            <span
              v-if="props.row.blockedSecurity"
              class="badge badge-danger"
            >Блокировка СБ</span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="props.row.status === 4"
              v-b-tooltip.hover.bottom="'Отправить на верификацию в СБ'"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
              @click="customersContractorsTransportVehicleToVerification(props.row.contractorVehicles[0].contractor.id, props.row.contractorVehicles[0].id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/customers/transport-vehicle/id' + props.row.id"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="props.row.status === 4"
              v-b-tooltip.hover.bottom="'Вернуть на редактирование'"
              class="fa fa-reply fa-lg text-primary table-action-button"
              @click="confirmReturnVehicle(props.row.contractorVehicles[0].contractor.id, props.row.contractorVehicles[0].id, props.row.contractorVehicles)"
            />
            <a
              v-if="props.row.status === 4"
              v-b-tooltip.hover.bottom="'Отклонить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmRefusalVehicle(props.row.contractorVehicles[0].contractor.id, props.row.contractorVehicles[0].id, props.row.contractorVehicles)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="confirmReturnVehicleModal.show"
      :title="confirmReturnVehicleModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="returnVehicle(confirmReturnVehicleContractorId, confirmReturnVehicleId)"
    >
      <p>{{ confirmReturnVehicleModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmReturnVehicleModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmReturnVehicleModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
    <b-modal
      v-model="confirmRefusalVehicleModal.show"
      :title="confirmRefusalVehicleModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="refusalVehicle(confirmRefusalVehicleContractorId, confirmRefusalVehicleId)"
    >
      <p>{{ confirmRefusalVehicleModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmRefusalVehicleModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmRefusalVehicleModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customersTransportVehicleList,
  customersContractorsTransportVehicleToVerification,
  customersContractorsTransportVehicleToNew,
  customersContractorsTransportVehicleToCancel,
  vehicleModelsTypesList,
} from '../../services/api';
import {queryToUrl, URLToQuery} from '../../services/http';
import moment from 'moment';

Vue.use(ServerTable);

export default {
  name: 'CustomersTransportVehicles',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        grn: '',
        sts: '',
        status: null,
        type: null,
      },
      statusOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Допущены к рейсам'},
        {value: 2, text: 'На верификации СБ'},
        {value: 0, text: 'Новые'},
        {value: 3, text: 'Не допущенные'},
        {value: 4, text: 'На проверке логистом'},
        {value: 5, text: 'Отклоненые логистом'},
      ],
      typeOptions: [
        {value: null, text: 'Все'},
      ],
      confirmReturnVehicleContractorId: null,
      confirmReturnVehicleId: null,
      confirmReturnVehicleModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      confirmRefusalVehicleContractorId: null,
      confirmRefusalVehicleId: null,
      confirmRefusalVehicleModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      loading: false,
      columns: [
        'model.type.name',
        'model.name',
        'registrationNumber',
        'certificateRegistrationVehicleSeriesNumber',
        'contractor',
        'status',
        'actions',
      ],
      data: [],
      options: {
        customFilters: ['grn', 'sts', 'status', 'type'],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersTransportVehicleList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'model.type.name': 'Тип',
          'model.name': 'Марка',
          'registrationNumber': 'ГРН',
          'status': 'Допуск',
          'certificateRegistrationVehicleSeriesNumber': 'СТС',
          'contractor': 'Перевозчик',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
    this.vehicleModelsTypesList();
  },
  methods: {
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    async vehicleModelsTypesList(params) {
      this.loading = true;
      const response = await vehicleModelsTypesList(params);
      if (response && response.status === 200) {
        this.typeOptions = this.typeOptions.concat(
          response.data.items.map((item) => {
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    async returnVehicle(contractorId, contractorVehicleId) {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToNew(contractorId, contractorVehicleId, this.confirmReturnVehicleModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство возвращено на редактирование');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmReturnVehicle(contractorId, contractorVehicleId, contractorVehicles) {
      this.confirmReturnVehicleContractorId = contractorId;
      this.confirmReturnVehicleId = contractorVehicleId;
      this.confirmReturnVehicleModal = {
        show: true,
        title: 'Возврат транспортного средства на редактирование',
        desc: 'Для того, чтобы вернуть транспортное средство на редактирование, необходимо указать причину возврата. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
      if (contractorVehicles.length > 0 && contractorVehicles[0].customerComment) {
        this.confirmReturnVehicleModal.comment = contractorVehicles[0].customerComment;
      }
    },
    async refusalVehicle(contractorId, contractorVehicleId) {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToCancel(contractorId, contractorVehicleId, this.confirmRefusalVehicleModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отклонено');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmRefusalVehicle(contractorId, contractorVehicleId, contractorVehicles) {
      this.confirmRefusalVehicleContractorId = contractorId;
      this.confirmRefusalVehicleId = contractorVehicleId;
      this.confirmRefusalVehicleModal = {
        show: true,
        title: 'Отклонение транспортного средства',
        desc: 'Для того, чтобы отклонить транспортное средство, необходимо указать причину отклонения. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
      if (contractorVehicles.length > 0 && contractorVehicles[0].customerComment) {
        this.confirmRefusalVehicleModal.comment = contractorVehicles[0].customerComment;
      }
    },
    async customersContractorsTransportVehicleToVerification(contractorId, contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToVerification(contractorId, contractorDriverId);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отправлено на верификацию в СБ');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.grn) {
        res.grn = this.filter.grn;
      }
      if (this.filter.status !== null) {
        res.status = Number(this.filter.status);
      }
      if (this.filter.type !== null) {
        res.type = Number(this.filter.type);
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('status')) {
          params.status = Number(params.status);
        }
        if (params.hasOwnProperty('type')) {
          params.type = Number(params.type);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        console.log(params);
        if (params) {
          if (!params.grn && !params.sts && !params.status && !params.type && params.page === 1) {
            params.status = 4;
          }
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>
